<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-11 15:34:25
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-14 15:18:07
 * @FilePath: \sipingnongzhuan2\src\components\list\components\details.vue
-->
<template>
  <div class="news_content"
    style="max-width:100%">
    <div class="news_details">
      <div class="news_title">
        {{details.title}}
        <div
          class="title_times">
          <span
            v-if="details.reserve1">通讯员：{{details.reserve1}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span
            v-if="details.time">发表时间：{{details.time}}</span>
        </div>
      </div>
      <div
        class="news_detail_content"
        v-html="details.content">
      </div>
      <div @click="goPage()"
        class="news_btn news_btn_btn">
        <span
          class="news_btn_span">返回列表&gt;&gt;</span>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  props: ['value'],
  data() {
    return {
      isPhone: 'false',
      details: {},
      id: ''
    }
  },
  watch: {
    $route: {
      handler: function () {
        this.$emit('go', { view: 'ActiveList' })
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    getNews() {
      // axios
      //   .post('/home/news/info', {
      //     id: this.value
      //   })
      //   .then(res => {
      //     this.details = res.data.data.news
      //     this.details.times = this.details.time.substring(0, 10)
      //   })
      this.$post('/detailsContent/selectById', { id: this.value }).then(res => {
        this.details = res.data.detailsContent
      })
    },
    goPage() {
      if (this.$route.query.ids) {
        this.$emit('go', { view: 'ActiveList' })
      } else {
        this.$router.push({ path: this.$store.state.path, query: { id: this.$store.state.id } })
      }
    }
  }
}
</script>

<style>
.news_btn_btn {
  float: inherit;
}
.news_content .news_details .news_btn.news_btn_btn .news_btn_span {
  margin: 20px auto !important;
}
.news_detail_content p img {
  display: inline !important;
}
.news_content img {
  max-width: calc(100% - 40px);
}
.news_content table {
  max-width: calc(100% - 40px);
}
</style>