<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-04-25 14:19:45
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-10 13:38:54
 * @FilePath: \sipingnongzhuan2\src\components\list\index.vue
-->
<template>
  <div>
    <!-- 载入组件 -->
    <transition
      name="fade-transform"
      mode="out-in">
      <component :is="view"
        v-bind="props"
        @go="linkname" />
    </transition>
    <!-- 载入组件 -->
  </div>
</template>

<script>
// 引入组件及方法
import ActiveList from './components/list.vue' // 首页
import ActiveDetails from './components/details.vue' // 首页

export default {
  components: { ActiveList, ActiveDetails },
  data() {
    return {
      view: 'ActiveList', // 组件
      props: {} // 内容
    }
  },
  //  初始化
  mounted() {
    if (this.$route.query.ids) {
      const e = { view: 'ActiveDetails', value: this.$route.query.ids }
      this.linkname(e)
    } else {
      this.view = 'ActiveList'
    }
  },
  methods: {
    // 获取组件
    linkname(e) {
      console.log(e)
      this.view = e.view
      this.props.value = e.value
    }
  }
}
</script>

<style></style>
