<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-11 14:59:11
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-22 09:41:59
 * @FilePath: \sipingnongzhuan2\src\components\list\components\list.vue
-->
<template>
  <div class="news_contents">
    <!-- <img v-if="loading"
      src="../../../assets/imgaes/loading.7.gif"
      style="height:300px;margin: 0 auto;display: block;"> -->
    <div
      v-if="isPhone == 'false'"
      style="
max-width: 1160px;
    margin: 0 auto !important;
    padding: 0 20px;">
      <div
        v-if="newsList.length>0"
        class="news_contents_a">
        <div
          class="news_items"
          v-for="(item,index) in newsList"
          :key="index">
          <div
            class="news_more"
            @click="gotoDetails(item)">
            <el-row
              :gutter="20">
              <el-col :md="3"
                :lg="3">
                <div
                  style="margin-top: 17px; height: 80px;">
                  <img
                    :src="item.imgUrl"
                    style="width:100px;height:70px">
                </div>
              </el-col>
              <el-col :md="18"
                :lg="18">
                <div
                  class="items_content">
                  <div
                    class="items_title">
                    {{item.title}}
                  </div>
                  <div
                    class="items_body">
                    <div
                      class="items_body_content">
                      <p>
                        {{item.introduction}}
                      </p>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col
                style="margin-top:20px"
                :md="3"
                :lg="3">
                <div
                  class="items_date">
                  <div
                    class="items_date_div">
                    <div
                      style="margin: 16px 0px;">
                      <i class="el-icon-date"
                        style="font-size:20px"></i>
                    </div>
                    <span>{{item.time}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-pagination
          style="margin-top: 20px; text-align: right;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
      <div v-else
        style="width:100%;text-align:center">
        <img
          style="width:250px;margin:auto"
          src="../../../assets/imgaes/no_data.png"
          alt="">
      </div>
    </div>
    <div v-else style="
max-width: 1160px;
    margin: 0 auto !important;
    margin-top:-20px;
    padding: 0 10px;">
      <div
        class="news_contents_a">
        <div
          class="news_items"
          v-for="(item,index) in newsList"
          :key="index">
          <div
            class="news_more"
            @click="gotoDetails(item)">
            <el-row
              :gutter="20">
              <el-col
                :span="8"
                :md="3"
                :lg="3">
                <div
                  style="margin-top: 17px; height: 80px;">
                  <img
                    :src="item.imgUrl"
                    style="width:100%;height:70px">
                </div>
              </el-col>
              <el-col
                style="padding:0"
                :span="10"
                :md="18"
                :lg="18">
                <div
                  class="items_content">
                  <div
                    style="-webkit-line-clamp: 3;font-size:13px"
                    class="items_title">
                    {{item.title}}
                  </div>
                  <div
                    class="items_body">
                    <div
                      class="items_body_content">
                      <p>
                        {{item.introduction}}
                      </p>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col
                :span="6"
                :md="3"
                :lg="3">
                <div
                  style="padding:0px"
                  class="items_date">
                  <div
                    class="items_date_div">
                    <div
                      style="margin: 18px 0px 0px 0px;">
                      <i class="el-icon-date"
                        style="font-size:15px"></i>
                    </div>
                    <span
                      style="font-size:8px">{{item.time}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-pagination
          style="margin-top: 20px; text-align: right;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      newFirstObj: {},
      newsList: [],
      isPhone: 'false',
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  },
  watch: {
    $route: {
      handler: function () {
        if (this.pageNum != 1) {
          this.pageNum = 1
          this.pageSize = 10
        }
        console.log(this.$route.query)
        this.getList()
        this.$emit('go', { view: 'ActiveList' })
      },
      // 深度观察监听
      deep: true
    }
  },
  // watch: {
  //   $route(to, from) {
  //     console.log(to)
  //     console.log(from)
  //   }
  // },
  mounted() {
    if (window.localStorage.getItem('isPhone')) this.isPhone = window.localStorage.getItem('isPhone')
    else this.isPhone = 'false'
    if (this.pageNum != 1) {
      this.pageNum = 1
      this.pageSize = 10
    }
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      console.log(this.$route.query.id)
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        isEnable: 0,
        projectId: this.$route.query.id
      }
      this.$post('/detailsContent/selectListByProjectId', obj).then(res => {
        this.loading = false
        this.total = parseInt(res.data.total)
        console.log(res.data.list)
        this.newsList = res.data.list
        this.newsList.map(item => {
          this.$set(item, 'imgUrl', res.data.imgUrl + '/' + item.url)
        })
      })
    },
    gotoDetails(item) {
      // this.$router.push({
      //   path: '/news_details',
      //   query: {
      //     id: item.id
      //   }
      // })
      this.$router.push(`${this.$route.path}?id=${item.id}`)
      this.$emit('go', { view: 'ActiveDetails', value: item.id })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    goTop() {
      var timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        var oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
        }
      })
    }
  }
}
</script>

<style>
/* .news_mid {
  max-width: 1160px;
  margin: 0 auto !important;
  padding: 0 20px;
} */
</style>